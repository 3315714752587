<template>
  <div class="login-container">
    <el-card class="login-form">
      <h1>登录管理系统</h1>
      <el-form :model="form" label-width="80px">
        <el-form-item label="账号">
          <el-input v-model="form.userName" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="form.passWord" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { login } from "@/api/user";
export default {
  data() {
    return {
      form: {
        userName: "",
        passWord: "",
      },
    };
  },
  methods: {
    onSubmit() {
      login({ userName: this.form.userName, passWord: this.form.passWord }).then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userName", res.data.userName);
        localStorage.setItem("name", res.data.name);
        localStorage.setItem("role", res.data.role);
        this.$message({
          message: "登录成功",
          type: "success"
        });
        this.$router.push("/home");
      }).catch((value) => {
        this.$message({
          type: "error",
          message: value.msg
        })
      })
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("../../public/img/doctor.jpeg") no-repeat center center;
  background-size: cover;
}

.login-form {
  background-color: rgba(255, 255, 255, 0.8); /* 登录框为半透明 */
  padding: 20px;
  border-radius: 15px; /* 圆角边框 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 500px;
  height: 400px;
  transition: box-shadow 0.3s ease; /* 增加过渡效果 */
}

h1 {
  text-align: center;
  margin-bottom: 40px;
}

.el-form {
  height: 70%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 16px !important;
}

::v-deep .el-form-item__label {
  font-size: 16px;
  width: 60px !important;
}

.el-button {
  width: 100%;
}
</style>