import request from './request';

export function login(data) {
  return request.post('/system/login', data);
}

export function logout() {
  return request.get('/system/logout');
}

// 用户列表
// /user/queryPage?current=1&size=1
export function getUserList(params) {
  return request.get('/user/queryPage', { params });
}

// 根据用户id查询用户信息
///user/info?id=1
export function getUserInfo(params) {
  return request.get('/user/info', { params });
}

// 添加或者修改用户
export function saveUser(data) {
  return request.post('/user/save', data);
}

// 删除用户
// DELETE /user/deleted?id=3
export function deleteUser(params) {
  return request.delete('/user/deleted', { params });
}